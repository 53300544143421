.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--top-border-b-color);
    height: 60px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: var(--bg-color);
    transition:background-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out; 
}
.hamburger {
    display: none;
    color: var(--date-wrapper-clr);;
  }
.left-header-wrapper{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: .1px .5rem;
    width: 30%;
    border-radius: 6px;
    color: var(--left-header-wrapper-clr);
    background-color:var(--left-header-wrapper-bg);
    transition:background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.left-header-wrapper input{
    all: unset;
    text-align: start;
    padding: .4rem;
    width: 100%;
}
.left-header-wrapper input::placeholder{
    font-size: 12px;
    font-weight: 100;
    font-family: 'Courier New', Courier, monospace;
    color: var(--left-header-wrapper-clr);
    transition:color 0.3s ease-in-out,
}
.right-header-wrapper{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: .6rem;
    width: 60%;
}
.toggle-mode{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4rem;
    padding: .2rem;
    border-radius: 6px;
    background-color: var( --toggle-bg-clr);
    border:var(--toggle-border-clr);
    transition:background-color 0.3s ease-in-out, border 0.3s ease-in-out
}


 .light-btn, .dark-btn{
    all: unset;
    font-size: 10px; 
    cursor: pointer;
 }

.active-mode{
    background-color:var(--toggle-active-clr) ;
    padding: .4rem;
    border-radius: 3px;
    color:var( --toggle-active-txt-clr);
    transition:color 0.3s ease-in-out, background-color 0.3s ease-in-out; 
}
.not-active-mode{
    color: gray;
}
.notification-icon-wrapper{
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    padding: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ntn-wrapper-bg-clr);
    transition:background-color 0.3s ease-in-out,
}
.ntn-btn{
    color: var(--ntn-btn-clr);
}
.divider{
    border-right: 1px solid rgb(160, 158, 158);
    height: 20px;
}

.img-wrapper{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
}
.name-greeting{
    margin: 0;
 font-size: smaller;
}

.cat-header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: .1rem .3rem;
    position: relative;
    color:var(--ntn-btn-clr) ;
    background-color: var( --toggle-bg-clr);
    border-radius: 6px;
}
.cat{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cat-header-wrapper:hover{
    color: rgb(34, 16, 238);
    background-color: rgb(235, 235, 243);
    border-radius: 6px;
}
.cat-header{
    margin: 0;
    font-size: small;
    text-transform: capitalize;
}
.cat-item{
    text-transform: capitalize; 
}
.dropdown-wrapper{
    margin-top: 0;
    width: 100px;
    list-style-type: none;
    padding: .4rem;
    padding-top: 0;
    padding-left: 0;
    border-radius: 6px;
   background-color: var(--dropdown-wrapper-bg);
   position: absolute;
   top: 35px;
   left: 0;
   
}
.dropdown-wrapper li{
    font-size: small;
    cursor: pointer;
    color: var(--dropdown-li-clr);
    margin-top: .4rem;
    padding-left: .4rem;
    padding-bottom: .2rem;
   
    border-bottom: var( --dropdowwn-li-br)
}

.dropdown-wrapper li:hover{
    color: rgb(34, 16, 238);
    background-color: white;
    border-radius: 2px;
}
.dropdown-wrapper li:last-child{
    border-bottom:none;
    margin-top: 0;
    padding-top: .2rem;
}
@media(max-width:630px){
    .notification-icon-wrapper{
        display: none;
    }
    .divider{
        display: none;
    }
}
@media (max-width: 770px) {
    
    .hamburger {
      display: block;
      font-size: large;
      width: 7%;
    }
    .left-header-wrapper{
       width: 35%;
    }
    .right-header-wrapper{
        width: 65%;
    }
  }
