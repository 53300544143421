.logo-img{
    width: 50%;
    display:flex;
}
.sidebar {
    background-color: var(--bg-color); 
    padding: 20px;
    border-right: var(--side-border-r-color);
    transition:background-color 0.3s ease-in-out 
  }
.logo-wrapper{
    display: flex;
    align-items: center;
    gap: .4rem;
}
.side-logo{
    color:var(--logo-color)
}
.logo-title{
    color: var(--logo-title-color);
}
.header-wrapper{
    display: flex;
    flex-direction: column;
    
}
.side-options{
    margin-top: 2rem;
    
}
.options-ul{
    display: flex;
    justify-content: center;
   
    flex-direction: column;
    gap: 1.3rem;
    width: 100%;
    margin-top: 0;
    list-style-type: none;
    padding: .4rem;
    padding-top: 0;
    padding-left: 0;
   background-color: var(--side-options-ul-bg);
   transition:background-color 0.3s ease-in-out; 
 
 
}
.options-ul li{
    font-size: small;
    cursor: pointer;
    padding: .5rem;
    height: 15px;
    color: var(--text-color);
    position: relative;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;

}
.options-ul li:hover,
.options-ul li.active{
    color: var(--side-options-hover-clr);
    background-color: var(--side-options-hover-bg);
    border-radius: 4px;
}
.close-btn{
    display: none;
    color: var(--date-wrapper-clr);;
}
.options-ul li.active::before{
content: '';
  position: absolute;
  left: 0;
  top: 50%;
  bottom: 50%;
  width: 3.6px; 
  height: 60%;
  transform: translateY(-50%);
  background-color: var(--side-before-bg);
  border-top-right-radius: 5px; 
  border-bottom-right-radius: 5px; 
  transition: width 0.3s ease, height 0.3s ease; 
}
@media (max-width: 770px) {
    .close-btn{
        display: block;
        
        position: absolute;
        right: 6px;
        font-size: large;
    }
    .sidebar {
    
        position: fixed;
        left: 0;
        bottom: 0;
        top: 0;
         width: 50%;
         z-index: 3;
      
      }
    .open{
        transform: translateX(0);
        /* visibility: visible;
        opacity: 1; */
        transition: transform 0.5s ease-in-out;
        /* transition: opacity 0.3s ease, visibility 0.3s ease; */
    }
    .close{
         
        transform: translateX(-100%); 
        transition: transform 0.5s ease-in-out;
        /* visibility: hidden;
        opacity: 0; */
        /* transition: opacity 0.3s ease, visibility 0.3s ease; */
    }
}