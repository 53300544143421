.App {
  display: grid;
  grid-template-columns: 15% 85%; 
  height: 100vh;
  width: 100vw;
}
p{
  margin: 0;
}


.main {
  background-color: var(--main-bg-color);
  transition:background-color 0.3s ease-in-out 
}
@media (max-width: 1040px) {
  .App {
    display: grid;
    grid-template-columns: 20% 80%;
  }  
}
@media (max-width: 770px) {
  .App {
    
    display: grid;
    grid-template-columns: 1fr;
  }  

}